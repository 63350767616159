<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/generic/LoadingSpinner.vue";
export default defineComponent({
  components: {
    LoadingSpinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>
<template>
  <button
    :disabled="disabled"
    :class="[
      disabled
        ? 'cursor-default bg-gray-200 text-gray-300 dark:bg-gray-500 dark:text-gray-400'
        : ' bg-blue-300 text-black hover:bg-gray-200 dark:border dark:border-blue-500 dark:bg-black dark:text-blue-500 dark:hover:bg-blue-900',
      '', // class for controlling the background opacity in dark mode
    ]"
    class="max-height-4 inline-flex items-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium focus:outline-none focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
  >
    <LoadingSpinner
      v-if="loading"
      class="mx-2"
    /><slot />{{ label }}
  </button>
</template>
