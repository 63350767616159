<template>
  <div class="flex justify-center mx-auto m-8">
    <div class="block">
      <span class="text-md flex text-gray-500 dark:text-gray-300">404</span>
      <h1 class="text-xl my-2">
        Page not found
      </h1>
      <p class="text-md flex my-4">
        Sorry, we couldn't find the page you're looking for.
      </p>
    </div>
  </div>
</template>
  <script setup>
</script>