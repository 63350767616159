<script setup>
</script>
<template>
  <button
    type="button"
    class="
      dark:text-gray-200
      dark:hover:bg-gray-700
      py-2
      px-4
      border border-gray-300
      rounded-full
      shadow-sm
      text-sm
      font-medium
      text-gray-700
      hover:bg-gray-50
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
    "
  >
    Close
  </button>
</template>