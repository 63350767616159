<script lang="ts">
import { defineComponent } from "vue";
import CheckCircleIcon from "../icons/CheckCircleIcon.vue";
import XMarkIcon from "../icons/XmarkIcon.vue";

export default defineComponent({
  name: "NotificationComponent",
  components: {
    CheckCircleIcon,
    XMarkIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 mt-12 sm:items-start sm:p-6 z-50"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="bg-white dark:bg-gray-700 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-50"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="font-medium text-sm text-gray-900 dark:text-gray-200">
                  {{ title }}
                </p>
                <p
                  v-if="detail"
                  class="mt-1 text-sm text-gray-500 dark:text-gray-200"
                >
                  {{ detail }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-full text-gray-400 hover:text-gray-500 dark:hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="$emit('closeNotification')"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
